// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-amiaworker-index-js": () => import("./../../../src/pages/amiaworker/index.js" /* webpackChunkName: "component---src-pages-amiaworker-index-js" */),
  "component---src-pages-amiaworker-question-10-js": () => import("./../../../src/pages/amiaworker/question10.js" /* webpackChunkName: "component---src-pages-amiaworker-question-10-js" */),
  "component---src-pages-amiaworker-question-11-js": () => import("./../../../src/pages/amiaworker/question11.js" /* webpackChunkName: "component---src-pages-amiaworker-question-11-js" */),
  "component---src-pages-amiaworker-question-12-js": () => import("./../../../src/pages/amiaworker/question12.js" /* webpackChunkName: "component---src-pages-amiaworker-question-12-js" */),
  "component---src-pages-amiaworker-question-13-js": () => import("./../../../src/pages/amiaworker/question13.js" /* webpackChunkName: "component---src-pages-amiaworker-question-13-js" */),
  "component---src-pages-amiaworker-question-2-js": () => import("./../../../src/pages/amiaworker/question2.js" /* webpackChunkName: "component---src-pages-amiaworker-question-2-js" */),
  "component---src-pages-amiaworker-question-3-js": () => import("./../../../src/pages/amiaworker/question3.js" /* webpackChunkName: "component---src-pages-amiaworker-question-3-js" */),
  "component---src-pages-amiaworker-question-4-js": () => import("./../../../src/pages/amiaworker/question4.js" /* webpackChunkName: "component---src-pages-amiaworker-question-4-js" */),
  "component---src-pages-amiaworker-question-5-js": () => import("./../../../src/pages/amiaworker/question5.js" /* webpackChunkName: "component---src-pages-amiaworker-question-5-js" */),
  "component---src-pages-amiaworker-question-6-js": () => import("./../../../src/pages/amiaworker/question6.js" /* webpackChunkName: "component---src-pages-amiaworker-question-6-js" */),
  "component---src-pages-amiaworker-question-7-js": () => import("./../../../src/pages/amiaworker/question7.js" /* webpackChunkName: "component---src-pages-amiaworker-question-7-js" */),
  "component---src-pages-amiaworker-question-8-js": () => import("./../../../src/pages/amiaworker/question8.js" /* webpackChunkName: "component---src-pages-amiaworker-question-8-js" */),
  "component---src-pages-amiaworker-question-9-js": () => import("./../../../src/pages/amiaworker/question9.js" /* webpackChunkName: "component---src-pages-amiaworker-question-9-js" */),
  "component---src-pages-amiaworker-results-js": () => import("./../../../src/pages/amiaworker/results.js" /* webpackChunkName: "component---src-pages-amiaworker-results-js" */),
  "component---src-pages-blog-article-strapi-article-slug-js": () => import("./../../../src/pages/blog/article/{StrapiArticle.slug}.js" /* webpackChunkName: "component---src-pages-blog-article-strapi-article-slug-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-freetools-js": () => import("./../../../src/pages/freetools.js" /* webpackChunkName: "component---src-pages-freetools-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-redundancy-contact-us-js": () => import("./../../../src/pages/redundancy-contact-us.js" /* webpackChunkName: "component---src-pages-redundancy-contact-us-js" */),
  "component---src-pages-templates-js": () => import("./../../../src/pages/templates.js" /* webpackChunkName: "component---src-pages-templates-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog-index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */)
}

